// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-page-js": () => import("./../src/templates/contentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-vendor-landing-page-js": () => import("./../src/templates/vendorLandingPage.js" /* webpackChunkName: "component---src-templates-vendor-landing-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-js": () => import("./../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-produkter-js": () => import("./../src/pages/produkter.js" /* webpackChunkName: "component---src-pages-produkter-js" */)
}

